import styled from 'styled-components';

const Heading = styled.h1`
  font-size: 4rem;
  line-height: 110%;
  color: ${({ theme }) => theme.colors.greenDark};

  span.higlightGreen {
    background-color: ${({ theme }) => theme.colors.greenLight};
    border-radius: 10px;
    padding: 0 10px;
  }
`;

export default Heading;
