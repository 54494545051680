import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { BlogCard, CardBody, CardHeader } from '../components/styles/blogcard';
import { Bloggrid } from '../components/styles/bloggrid';
import Button from '../components/styles/button.styled';
import Heading from '../components/styles/heading.styled';
import { Section } from '../components/styles/section.styled';
import Subtitle from '../components/styles/subtitle.styled';

interface Props {
  data: {
    allMdx: {
      nodes: [];
    };
  };
}

type Node = {
  frontmatter: {
    category: string;
    title: string;
    slug: string;
    image: IGatsbyImageData;
    date: string;
    description: string;
  };
};

const BlogPage = ({ data }: Props) => (
  <Layout>
    <Seo />
    <Section>
      <Heading>Articles</Heading>
      <Bloggrid>
        {data.allMdx.nodes.map((node: Node) => {
          const newImage = getImage(node.frontmatter.image);
          return (
            <BlogCard>
              <CardHeader>
                <Link to={`/post/${node.frontmatter.slug}`}>
                  <GatsbyImage image={newImage} alt={node.frontmatter.title} />
                </Link>
              </CardHeader>
              <CardBody>
                <Link to={`/post/${node.frontmatter.slug}`}>
                  <h3>{node.frontmatter.title}</h3>
                  <small>
                    {node.frontmatter.date} | {node.frontmatter.category}
                  </small>
                </Link>
                <p>{node.frontmatter.description}</p>
                <Link to={`/post/${node.frontmatter.slug}`}>Read this 👉</Link>
              </CardBody>
            </BlogCard>
          );
        })}
      </Bloggrid>
    </Section>
    <Section className="emphasis center">
      <Subtitle className="bold">First, we chat</Subtitle>
      <p>
        I believe in win-win cooperation. That's why I listen and ask first, and
        only after we define the problem we can say if I can be helpful.
      </p>
      <Link to="/contact/">
        <Button variant="outlined">Ready to chat?</Button>
      </Link>
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          slug
          title
          category
          date(formatString: "DD. MM. YYYY")
          description
        }
      }
    }
  }
`;

export default BlogPage;
