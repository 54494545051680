import styled from 'styled-components';

const Subtitle = styled.h3`
  font-weight: 400;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.greenDark};

  span.higlightGreen {
    background-color: ${({ theme }) => theme.colors.greenLight};
    border-radius: 10px;
    padding: 0 10px;
  }

  &.bold {
    font-weight: 700;
  }
`;

export default Subtitle;