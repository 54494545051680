import styled from 'styled-components';

export const BlogCard = styled.div`
  display: flex;
  flex-direction: column;
  width: clamp(22rem, calc(22rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff;
  flex-grow: 1;

  && a {
    text-decoration: none;
  }
`;

export const CardHeader = styled.div`
  && img {
    max-width: 100%;
    display: block;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
  }
  && img:hover {
    transform: scale(1.1);
  }
`;

export const CardBody = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  && h3 {
    font-size: 1.15rem;
    text-transform: capitalize;
  }
`;
